:root {
    --chakra-colors-chakra-body-bg: #f5f5f5 !important;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.offday-calendar {
    width: 100%;
}

.prompt-input {
    border: none;
    outline: none;
    background: none;
    font-weight: 800;
    min-width: 0;
    width: 100%;
}

select.select-custom {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    width: 100%;
    height: 100%;
    padding: 0;
    color: #000000;
    cursor: pointer;
    background: none;
    border: none !important;
    font-size: 1em;
    outline: none !important;
}

@import "ka-table/style.scss";
@import "./ka-table.scss";
